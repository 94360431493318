import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  memberType = ['FOI', 'MGT'];
  // eslint-disable-next-line max-len
  theCities = ['San Antonio','Baton Rouge','Little Rock','Waxahachie','Tulsa','Homer','Greensboro', 'South Haven', 'Pittsburgh', 'Cleveland', 'Hartford', 'Birmingham', 'College Park-ATL', 'Buffalo', 'Milwaukee', 'Brooklyn', 'Rialto', 'Los Angeles', 'St. Petersburg', 'Miami', 'Ft. Lauderdale', 'Tampa', 'Pensacola', 'Jacksonville', 'Seattle', 'Sacramento', 'Compton', 'Stockton', 'Phoenix', 'Portland', 'Oakland', 'Las Vegas', 'Moreno Valley', 'Empire', 'San Diego', 'Detroit', 'Bennettsville', 'Martinsville', 'Winston-Salem', 'Charlotte', 'Wilmington', 'Boston', 'Toronto', 'Akron', 'Grand Rapids', 'St Louis', 'Cincinnati', 'Kansas City', 'Baltimore', 'Durham', 'Rochester', 'Utica', 'Chester', 'Newark', 'Philadelphia', 'New York', 'Chicago', 'Houston', 'Dallas', 'San Francisco', 'Memphis', 'Washington, DC', 'New Orleans', 'Austin', 'Shreveport', 'Oklahoma City', 'Ft. Worth', 'Atlanta', 'Monroe'];
  theRegions = ['Eastern', 'Western', 'Central', 'Southern', '7TH', 'Mid-Altantic', 'South West', 'Delaware Valley', 'Europe'];

  constructor(
    private alertController: AlertController,
    private afAnalytics: AngularFireAnalytics,
    private toastController: ToastController
  ) { }

  /**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param priorHours The initial value
 * @param currentHours The value that changed
 */
  getPercentageChange(priorHours, currentHours) {
    const decreaseValue = priorHours - currentHours;

    return (decreaseValue / priorHours) * 100;
  }

  percentIncrease(a, b) {
    let percent;
    if(b !== 0) {
        if(a !== 0) {
            percent = (b - a) / a * 100;
        } else {
            percent = b * 100;
        }
    } else {
        percent = - a * 100;
    }
    return Math.floor(percent);
  }

  logEvent(eventName: string, eventParams?: any) {
    if (eventName.toUpperCase() === 'SET_USER_ID') {
      this.afAnalytics.setUserId(eventParams.userId);
    } else {
      this.afAnalytics.logEvent(eventName, { eventParams });
    }
  }

  deviceCheck = async () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return isMobile;
  };

  showAlert = async (msg: string) => {
    const alert = await this.alertController.create({
      header: 'NOI Auditing Department',
      cssClass: 'alert-kem',
      message: msg,
      buttons: ['OK'],
      backdropDismiss: false
    });

    await alert.present();
  };

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 7000,
      position: 'bottom',
      cssClass: 'custom-toast',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel'
        }
      ]
    });

    await toast.present();
  }

  setReportMonth = () => this.monthNames[new Date().getMonth()];

  setDate = (date) => {
    if (date !== 'string') {
      const fdate = new Date((date));
      return moment(fdate).format('MM/DD/YYYY');
    }
    moment(date).format('MM/DD/YYYY');
  };

  getMomentDate() {
    return moment().format('MMMM Do YYYY, h:mm:ss a');
  }

  getMonthFromString = (mon) => new Date(Date.parse(mon + ' 1, 2022')).getMonth();

  currentDate = () => moment().format('MM/DD/YYYY');

  currentTime = () => moment().format('HH:mm:ss');

  currentTimestamp = () => moment().format('MM-DD-YYYY HH:mm:ss');

  currentMonth = () => moment().format('MMMM');

  priorMonth = () => moment().subtract(1, 'months').format('MMMM');

  archiveMonth = () => moment().subtract(2, 'months').format('MMMM');

  getMonthNumber = (date) => moment(date).month(); //.format('MM');
}
