import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IAuditors } from 'src/app/services/auditor';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-greet',
  templateUrl: './greet.component.html',
  styleUrls: ['./greet.component.scss'],
})
export class GreetComponent implements OnInit {
  name: string;
  imgSrc: string;
  location: string;
  today: number = Date.now();
  auditor: IAuditors[];

  constructor(
    private authService: AuthenticationService,
    public ngFireAuth: AngularFireAuth,
    private dataService: DataService
  ) {
    this.ngFireAuth.authState.subscribe((user) => {
      if (user) {
        this.getTheAuditor(user.email).then((res) => {
          this.auditor = Object.values(res);
          this.location = this.auditor[0].CITY + ', ' + this.auditor[0].REGION;
          this.name = this.auditor[0].FNAME + ' ' + this.auditor[0].LNAME;
        });
      }
    });
  }

  ngOnInit(): void {
  }

  getTheAuditor = async (email: string) => await this.dataService.getAuditorByEmail(email).toPromise();

}
