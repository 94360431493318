// import { Injectable } from '@angular/core';
// import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
// import { AlertController } from '@ionic/angular';
// import { interval } from 'rxjs';
// import { filter, map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
// export class PromptUpdateService {

//   constructor(
//     public swUpdate: SwUpdate,
//     public alertController: AlertController) {}

//   public checkForUpdates(): void {
//     if (this.swUpdate.isEnabled) {
//       this.swUpdate.versionUpdates.subscribe((evt: VersionReadyEvent) => {
//         // console.log('evt: ', evt);
//         if ( evt.type === 'VERSION_READY' ) {
//           this.showConfirm();
//         }
//       });
//     }
//   }

//   showConfirm() {
//     this.alertController.create({
//       header: 'NOI Auditing Department',
//       cssClass: 'alert-kem',
//       message: 'New version available. Please Load The New Version?',
//       buttons: [
//         {
//           text: 'Cancel',
//           handler: () => {
//             // this.closeVersion();
//           }
//         },
//         {
//           text: 'Yes!',
//           handler: () => {
//             window.location.reload();
//           }
//         }
//       ]
//     }).then(res => {
//       res.present();
//     });
//   }

//   private promptUser(): void {
//     // console.log('updating to new version');
//     this.swUpdate.activateUpdate().then(() => document.location.reload());
//   }

// }

import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PromptUpdateService {

  constructor(
    private swUpdate: SwUpdate,
    private alertController: AlertController
  ) {}

  public checkForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((evt: VersionReadyEvent) => {
        if (evt.type === 'VERSION_READY') {
          this.promptUserToUpdate();
        }
      });
    }
  }

  private promptUserToUpdate(): void {
    this.alertController.create({
      header: 'NOI Auditing Department',
      cssClass: 'alert-kem',
      message: 'New version available. Please Load The New Version?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Yes!',
          handler: () => {
            this.updateApp();
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  private updateApp(): void {
    this.swUpdate.activateUpdate().then(() => window.location.reload());
  }
}
