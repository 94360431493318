import { Injectable } from '@angular/core';
import { CanLoad, Router, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    private utility: UtilityService
  ){ }
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   if(this.authService.isLoggedIn() !== true) {
  //     this.router.navigate(['login'])
  //     this.utility.showAlert('You must be logged in to access our services. Please Log In.');
  //   }
  //   return true;
  // }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
      this.utility.showAlert('You must be logged in to access our services. Please Log In.');
    }
    return this.authService.isLoggedIn();
  }
}
