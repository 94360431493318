/* eslint-disable max-len */
// import { Component, Input, OnInit } from '@angular/core';
// import { IServices } from 'src/app/services/services';
// import { ISuccess } from 'src/app/services/success';

// @Component({
// selector: 'app-region-card',
// templateUrl: './region-card.component.html',
// styleUrls: ['./region-card.component.scss'],
// })
// export class RegionCardComponent implements OnInit {
// @Input() region: string;
// @Input() regionData: IServices[];
// @Input() successData: ISuccess[];

// displayData = [];
// totaled = 0;
// storycount = 0;

// constructor() { }

// ngOnInit() {
//     this.getRegionData();
// }

// getRegionData() {//Mid-Altantic
//     let tempData = [];

//     // eslint-disable-next-line max-len
//     const tempSuccess = this.successData.filter((item) => new Date(item.loadDate).getFullYear() === new Date().getFullYear() && item.region === this.region);
//     if (tempSuccess.length > 0) {
//       this.storycount = tempSuccess.length;
//     } else {
//       this.storycount = 0;
//     }

//     if(this.region.toLocaleUpperCase() === 'MID-ATLANTIC'){
//       tempData = this.regionData.filter((item) => item.region.toUpperCase() === 'MID-ALTANTIC');
//     } else {
//       tempData = this.regionData.filter((item) => item.region.toUpperCase() === this.region.toUpperCase());
//     }

//     if(tempData.length > 0){
//       this.displayData = [{
//         bookOneHours: tempData.map((item) => parseFloat(item.bookOneHours))
//           .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

//         illinessHours: tempData.map((item) => parseFloat(item.illinessHours))
//           .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

//         otherBookHours: tempData.map((item) => parseFloat(item.otherBookHours))
//           .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

//         selfAnalysisHours: tempData.map((item) => parseFloat(item.selfAnalysisHours))
//           .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

//         straightWireHours: tempData.map((item) => parseFloat(item.straightWireHours))
//           .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

//         supervisingHours: tempData.map((item) => parseFloat(item.supervisingHours))
//           .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString()
//       }];

//       this.totaled = Number(this.displayData[0].supervisingHours)
//         + Number(this.displayData[0].straightWireHours)
//         + Number(this.displayData[0].bookOneHours)
//         + Number(this.displayData[0].illinessHours)
//         + Number(this.displayData[0].otherBookHours)
//         + Number(this.displayData[0].selfAnalysisHours);
//     }
//     else {
//       this.displayData = [{
//         bookOneHours: 0,
//         illinessHours: 0,
//         otherBookHours: 0,
//         selfAnalysisHours: 0,
//         straightWireHours: 0,
//         supervisingHours: 0
//       }];;
//     }

//     // return this.regionData;
// }

// }

import { Component, Input, OnInit } from '@angular/core';
import { IServices } from 'src/app/services/services';
import { ISuccess } from 'src/app/services/success';
import * as moment from 'moment';

@Component({
  selector: 'app-region-card',
  templateUrl: './region-card.component.html',
  styleUrls: ['./region-card.component.scss'],
})
export class RegionCardComponent implements OnInit {
  @Input() region: string;
  @Input() regionData: IServices[];
  @Input() successData: ISuccess[];

  displayData = [];
  totaled = 0;
  storycount = 0;

  constructor() { }

  ngOnInit() {
    this.getRegionData();
  }

  getRegionData() {
    // Filter success stories for the current year and region
    const currentYearSuccessStories = this.successData.filter(item =>
      moment(item.loadDate).year() === moment().year() && item.region === (this.region === 'Mid-Atlantic' ? 'Mid-Altantic' : this.region)
    );
    this.storycount = currentYearSuccessStories.length;

    // Filter region data based on the region name
    const filteredRegionData = this.regionData.filter(item =>
      this.region.toUpperCase() === 'MID-ATLANTIC' ?
        item.region.toUpperCase() === 'MID-ALTANTIC' :
        item.region.toUpperCase() === this.region.toUpperCase()
    );

    // Calculate and assign values if filtered data is available
    if (filteredRegionData.length > 0) {
      this.displayData = [{
        bookOneHours: this.calculateTotalHours(filteredRegionData, 'bookOneHours').toString(),
        illinessHours: this.calculateTotalHours(filteredRegionData, 'illinessHours').toString(),
        otherBookHours: this.calculateTotalHours(filteredRegionData, 'otherBookHours').toString(),
        selfAnalysisHours: this.calculateTotalHours(filteredRegionData, 'selfAnalysisHours').toString(),
        straightWireHours: this.calculateTotalHours(filteredRegionData, 'straightWireHours').toString(),
        supervisingHours: this.calculateTotalHours(filteredRegionData, 'supervisingHours').toString()
      }];

      this.totaled = Number(Object.values(this.displayData[0]).reduce((acc: number, val: string) => acc + parseFloat(val), 0));
    } else {
      this.displayData = [{
        bookOneHours: '0',
        illinessHours: '0',
        otherBookHours: '0',
        selfAnalysisHours: '0',
        straightWireHours: '0',
        supervisingHours: '0'
      }];
    }
  }

  // Helper function to calculate total hours for a specific type
  calculateTotalHours(data: IServices[], type: string): number {
    return data.map(item => parseFloat(item[type]))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
}
