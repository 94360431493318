import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'password',
    loadChildren: () => import('./pages/auth/password/password.module').then( m => m.PasswordPageModule),
  },
  // {
  //   path: 'info',
  //   loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'add-hours',
    loadChildren: () => import('./pages/add-hours/add-hours.module').then( m => m.AddHoursPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'auditorinfo',
    loadChildren: () => import('./pages/auditorinfo/auditorinfo.module').then( m => m.AuditorinfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/auth/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'monthly-stat',
    loadChildren: () => import('./pages/monthly-stat/monthly-stat.module').then( m => m.MonthlyStatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'auditor-hours',
    loadChildren: () => import('./pages/other-reports/pages/auditor-hours/auditor-hours.module').then( m => m.AuditorHoursPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule),
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ]
})
export class AppRoutingModule {}
