import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { CityCardComponent } from './citycard/city-card.component';
import { RegionCardComponent } from './region-card/region-card.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    BarChartComponent,
    CityCardComponent,
    RegionCardComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgChartsModule
  ],
  exports: [
    NgChartsModule,
    BarChartComponent,
    CityCardComponent,
    RegionCardComponent
  ]
})
export class ComponentsModule { }
