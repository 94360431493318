import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { IAuditors } from 'src/app/services/auditor';
import { DataService } from 'src/app/services/data.service';
import { IServices } from 'src/app/services/services';
import { ISuccess } from 'src/app/services/success';

@Component({
  selector: 'app-city-card',
  templateUrl: './city-card.component.html',
  styleUrls: ['./city-card.component.scss'],
})
export class CityCardComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @Input() city: string;
  @Input() cityData: IServices[];
  @Input() successCityData: ISuccess[];

  displayCityData = [];
  cityTotaled = 0;
  storyCityCount = 0;
  isModalOpen = false;
  displayCity: IServices[];
  displayAuditor = [];

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.getCityData();
  }

  getCityData() {//Mid-Altantic
    const tempData = this.cityData.filter((item) => item.city.toUpperCase() === this.city.toUpperCase());

    // eslint-disable-next-line max-len
    // const tempSuccess = this.successCityData.filter((item) => new Date(item.loadDate).getFullYear() === new Date().getFullYear() && item.city === this.city);
    const tempSuccess = this.successCityData.filter((item) => item.city === this.city);
    if (tempSuccess.length > 0) {
      this.storyCityCount = tempSuccess.length;
    } else {
      this.storyCityCount = 0;
    }

    if (tempData.length > 0) {
      this.displayCityData = [{
        bookOneHours: tempData.map((item) => parseFloat(item.bookOneHours))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

        illinessHours: tempData.map((item) => parseFloat(item.illinessHours))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

        otherBookHours: tempData.map((item) => parseFloat(item.otherBookHours))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

        selfAnalysisHours: tempData.map((item) => parseFloat(item.selfAnalysisHours))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

        straightWireHours: tempData.map((item) => parseFloat(item.straightWireHours))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

        supervisingHours: tempData.map((item) => parseFloat(item.supervisingHours))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString()
      }];

      this.cityTotaled = Number(this.displayCityData[0].supervisingHours)
        + Number(this.displayCityData[0].straightWireHours)
        + Number(this.displayCityData[0].bookOneHours)
        + Number(this.displayCityData[0].illinessHours)
        + Number(this.displayCityData[0].otherBookHours)
        + Number(this.displayCityData[0].selfAnalysisHours);
    }
    else {
      this.cityTotaled = 0;
    }

    // return this.cityData;
  }

  handleCardClick(isOpen: boolean) {
    const tempData = this.cityData.filter((item) => item.city.toUpperCase() === this.city.toUpperCase());
    const cityAuditors = [...new Set(tempData.map(x => x.natid))];
    this.displayAuditor = [];

    cityAuditors.forEach((auditor) => {
      this.dataService.getAuditorByNatID(auditor).then((data: IAuditors) => {
        const auditorData = Object.values(data)[0];
        const auditorServices = tempData.filter(item => item.natid === auditor);
        const citySuccess = this.successCityData.filter((item) => item.natid === auditor);

        const deliveredHours = auditorServices
          .filter(item => item.serviceType.toUpperCase() === 'PROVIDED')
          .reduce((acc, curr) => acc + (
            parseFloat(curr.bookOneHours)
            + parseFloat(curr.illinessHours)
            + parseFloat(curr.otherBookHours)
            + parseFloat(curr.selfAnalysisHours)
            + parseFloat(curr.straightWireHours)
            + parseFloat(curr.supervisingHours)
          ), 0);

        const receivedHours = auditorServices
          .filter(item => item.serviceType.toUpperCase() === 'RECEIVED')
          .reduce((acc, curr) => acc + (
            parseFloat(curr.bookOneHours)
            + parseFloat(curr.illinessHours)
            + parseFloat(curr.otherBookHours)
            + parseFloat(curr.selfAnalysisHours)
            + parseFloat(curr.straightWireHours)
            + parseFloat(curr.supervisingHours)
          ), 0);

        auditorData.deliveredHours = deliveredHours;
        auditorData.receivedHours = receivedHours;
        auditorData.totalHours = deliveredHours + receivedHours;

        if (citySuccess.length > 0) {
          auditorData.successCount = citySuccess.length;
        } else {
          auditorData.successCount = 0;
        }

        this.displayAuditor.push(auditorData);

        this.displayAuditor.sort((a, b) => a.FNAME.localeCompare(b.FNAME));
      });
    });

    this.isModalOpen = isOpen;
  }

  cancel() {
    // this.displayAuditor = [];
    // this.displayCityData = [];
    this.isModalOpen = false;
    this.modal.dismiss(null, 'cancel');
  }

}
