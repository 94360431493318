import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAnalyticsModule, UserTrackingService, ScreenTrackingService  } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { GreetComponent } from './components/greet/greet.component';
import { MenuComponent } from './components/menu/menu.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ComponentsModule } from './pages/reports/components/components.module';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

@NgModule({
  declarations: [AppComponent, GreetComponent, MenuComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
      platform: {
        desktop: (win) => !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent),
      },
    }),
    AppRoutingModule,
    FontAwesomeModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    ComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserTrackingService,
    ScreenTrackingService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}



// My Original Code Below 22:46 30 September 2023


// import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { RouteReuseStrategy } from '@angular/router';
// import { HttpClientModule } from '@angular/common/http';

// import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFireStorageModule } from '@angular/fire/compat/storage';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { AngularFireAnalyticsModule, UserTrackingService, ScreenTrackingService  } from '@angular/fire/compat/analytics';
// import { environment } from '../environments/environment';

// import { AppComponent } from './app.component';
// import { AppRoutingModule } from './app-routing.module';

// import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { GreetComponent } from './components/greet/greet.component';
// import { MenuComponent } from './components/menu/menu.component';
// import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
// import { ComponentsModule } from './pages/reports/components/components.module';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// @NgModule({
//   declarations: [AppComponent, GreetComponent, MenuComponent],
//   imports: [
//     AngularFireModule.initializeApp(environment.config),
//     AngularFireAuthModule,
//     AngularFirestoreModule,
//     AngularFireStorageModule,
//     AngularFireDatabaseModule,
//     AngularFireAnalyticsModule,
//     BrowserModule,
//     ComponentsModule,
//     IonicModule.forRoot({
//       platform: {
//         /** The default `desktop` function returns false for devices with a touchscreen.
//          *  This is not always wanted, so this function tests the User Agent instead.
//          * **/
//         desktop: (win) => {
//           const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
//           return !isMobile;
//         }
//       },
//       mode: 'ios',
//     }),
//     AppRoutingModule,
//     FontAwesomeModule,
//     HttpClientModule,
//     ServiceWorkerModule.register('ngsw-worker.js', {
//       enabled: true, //environment.production,
//       // Register the ServiceWorker as soon as the application is stable
//       // or after 30 seconds (whichever comes first).
//       // registrationStrategy: 'registerWhenStable:30000'
//       registrationStrategy: 'registerImmediately'
//     })
//   ],
//   providers: [
//     { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
//     // {provide: LocationStrategy, useClass: HashLocationStrategy},
//     UserTrackingService,
//     ScreenTrackingService
//   ],
//   bootstrap: [AppComponent],
// })
// export class AppModule {
//   constructor(library: FaIconLibrary) {
// 		library.addIconPacks(fas, fab, far);
// 	}
// }
