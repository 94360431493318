// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  config : {
    apiKey: 'AIzaSyCSlHO69zXGqgKt25b7mpHostChDFdKJ1I',
    authDomain: 'nationaldianetics.firebaseapp.com',
    databaseURL: 'https://nationaldianetics.firebaseio.com',
    projectId: 'nationaldianetics',
    storageBucket: 'nationaldianetics.appspot.com',
    appId: '1:76306478675:web:edb09120d22bfe15',
    measurementId: 'G-TP55K844ZG'
  },
  vapidkey: 'BPzxeIwexSGMloEOa3AsHFlZcpO5fAsGSRkM_GYexdbuj3V00ugoJTOl6RPcnejx0AQBe7YkwJbhemUvKjrZMUc'
};
