import { Component, OnInit } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { PromptUpdateService } from './services/prompt-update.service';
import { OneSignal } from 'onesignal-ngx';
// import { getMessaging, getToken } from 'firebase/messaging';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public menuShow: boolean;

  isOnline: boolean;
  modalVersion: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;

  // messaging = getMessaging();

  constructor(
    private sw: PromptUpdateService,
    private platform: Platform,
    public alertController: AlertController,
    private toastController: ToastController,
    private oneSignal: OneSignal
  ) {
    this.isOnline = false;
    this.modalVersion = false;
    this.sw.checkForUpdates();
    // this.oneSignal.init({
    //   appId: '6d54e304-d5a3-431b-9e9c-b6bf1e10d204',
    //   serviceWorkerParam: {
    //     scope: '/assets/js/OneSignalSDKWorker.js'
    //   },
    //   serviceWorkerPath: '/assets/js/OneSignalSDKWorker.js'
    // });
  }

  ngOnInit() {
    this.updateOnlineStatus();

    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    this.oneSignal.init({
      appId: '6d54e304-d5a3-431b-9e9c-b6bf1e10d204',
      serviceWorkerParam: {
        scope: '/assets/js/OneSignalSDKWorker.js'
      },
      serviceWorkerPath: '/assets/js/OneSignalSDKWorker.js'
    });

    // this.loadModalPwa();
  }

  async showToast(msg: string) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 3000,
        position: 'bottom'
      });

      await toast.present();
  }

  showConfirm() {
    this.alertController.create({
      header: 'NOI Auditing Department',
      cssClass: 'alert-kem',
      message: 'New version available. Please Load The New Version?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            this.closeVersion();
          }
        },
        {
          text: 'Yes!',
          handler: () => {
            this.updateVersion();
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  closeVersion(): void {
    this.modalVersion = false;
  }

  showAddToHome(msg: string): void {
    this.alertController.create({
      header: 'NOI Auditing Department',
      cssClass: 'alert-kem',
      message: msg,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            this.closePwa();
          }
        },
        {
          text: 'Yes!',
          handler: () => {
            this.modalPwaPlatform = undefined;
            this.addToHomeScreen();
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  addToHomeScreen(): void {
    // this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  closePwa(): void {
    this.modalPwaPlatform = undefined;
  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    // console.log(this.isOnline);
  }

  private loadModalPwa(): void {
    if ( this.platform.is('android') ) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
        this.showAddToHome('Add NOI Auditing Web App to home screen');
      });
    }

    if (this.platform.is('ios')) { // && this.platform.SAFARI) {
      const isInStandaloneMode = ('standalone' in window.navigator); // && ((<any>window.navigator)['standalone']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
        this.showAddToHome(`To install NOI Auditing Web App on your device, tap the "Menu" button
        <img src="https://res.cloudinary.com/rodrigokamada/image/upload/v1641089482/Blog/angular-pwa/safari_action_button_38x50.png" />
        and then "Add to home screen" button`);
      }
    }
  }

  // getToken(messaging, { vapidKey: environment.vapidkey }).then((currentToken) => {
  //   if (currentToken) {
  //     // Send the token to your server and update the UI if necessary
  //     // ...
  //   } else {
  //     // Show permission request UI
  //     console.log('No registration token available. Request permission to generate one.');
  //     // ...
  //   }
  // }).catch((err) => {
  //   console.log('An error occurred while retrieving token. ', err);
  //   // ...
  // });

}
