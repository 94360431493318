import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IServices } from 'src/app/services/services';

import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;
  @Input() regionData: IServices[];
  @Input() dataRegion: any[];
  @Input() cityData: any[];
  @Input() cardType: string;

  chartData = [];

  barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  regions         = ['Eastern', 'Western', 'Central', 'Southern', '7TH', 'Mid-Altantic', 'South West', 'Delaware Valley'];
  barChartLabels  = ['Eastern', 'Western', 'Central', 'Southern', '7TH', 'Mid-Atlantic', 'South West', 'Delaware Valley'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [DataLabelsPlugin];
  barChartData: ChartData<'bar'> = {
    labels: [ '2006', '2007', '2008', '2009', '2010', '2011', '2012' ],
    datasets: [
      { data: [ 65, 59, 80, 81, 56, 55, 40 ], label: 'Series A' },
      { data: [ 28, 48, 40, 19, 86, 27, 90 ], label: 'Series B' }
    ]
  };

  finalRegionData = [];

  constructor() {}

  ngOnInit() {
    if(this.cardType === 'national'){
      this.setChartData();
    } else if(this.cardType === 'city') {
      this.setCityData();
    } else if(this.cardType === 'region') {
      this.setRegionData();
    }
  }

  setRegionData() {
    this.barChartLabels = [];
    const regionTempData = [];
    this.dataRegion.forEach((item) => {
      this.barChartLabels.push(item.city);
      regionTempData.push(item.del + item.rec);
    });
    this.barChartData = {
      labels: this.barChartLabels,
      // eslint-disable-next-line max-len
      datasets: [{ data: regionTempData.sort((a, b) => (b.del + b.rec) - (a.del + b.rec)), label: new Date().getFullYear() + ' Region Hours'}]
    };
    this.chart?.update();
  }

  setCityData() {
    this.barChartLabels = [];
    const cityTempData = [];
    if(this.cityData.length <= 0){console.log('no data');}
    this.cityData.forEach((item) => {
      this.barChartLabels.push(item.auditor);
      cityTempData.push(item.total);
    });
    this.barChartData = {
      labels: this.barChartLabels,
      datasets: [ { data: cityTempData, label: new Date().getFullYear() + ' City Hours'} ]
      // data: cityTempData, labels: new Date().getFullYear() + ' City Hours'
    };

    this.chart?.update();
  }

  setChartData() {
    this.barChartLabels = [];
    this.regions.forEach((region) => {
      let tempData = [];
      tempData = this.regionData.filter((item) => item.region.toUpperCase() === region.toUpperCase());
      let theRegion = region;
      let tempData2 = [];
      if(tempData.length > 0){
        tempData2 = [{
          bookOneHours: tempData.map((item) => parseFloat(item.bookOneHours))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

          illinessHours: tempData.map((item) => parseFloat(item.illinessHours))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

          otherBookHours: tempData.map((item) => parseFloat(item.otherBookHours))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

          selfAnalysisHours: tempData.map((item) => parseFloat(item.selfAnalysisHours))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

          straightWireHours: tempData.map((item) => parseFloat(item.straightWireHours))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString(),

          supervisingHours: tempData.map((item) => parseFloat(item.supervisingHours))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString()
        }];

        const totaled = Number(tempData2[0].supervisingHours)
          + Number(tempData2[0].straightWireHours)
          + Number(tempData2[0].bookOneHours)
          + Number(tempData2[0].illinessHours)
          + Number(tempData2[0].otherBookHours)
          + Number(tempData2[0].selfAnalysisHours);

        // this.chartData.push(totaled);
        this.finalRegionData.push({
          region: region === 'Mid-Altantic' ? 'Mid-Atlantic' : region,
          total: totaled
        });
        this.finalRegionData.sort((a, b) => b.total - a.total);
      } else {
        // this.chartData.push(0);
        this.finalRegionData.push({
          region: region === 'Mid-Altantic' ? 'Mid-Atlantic' : region,
          total: 0
        });
        this.finalRegionData.sort((a, b) => b.total - a.total);
      }
    });

    this.finalRegionData.forEach((item) => {
      this.barChartLabels.push(item.region);
      this.chartData.push(item.total);
    });

    this.barChartData = {
      labels: this.barChartLabels,
      datasets: [ {data: this.chartData, label: new Date().getFullYear() + ' National Hours'} ]
    };

    this.chart?.update();
  }
  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  };

  // public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  //   console.log(event, active);
  // }

}
