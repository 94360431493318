import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menuShow = false;
  private auditor;
  private user;

  constructor(
    public authService: AuthenticationService,
    private route: Router,
    public ngFireAuth: AngularFireAuth,
    private dataService: DataService
  ) {
    this.ngFireAuth.authState.subscribe((user) => {
      if (user) {
        this.getTheAuditor(user.email).then((res) => {
          this.auditor = Object.values(res);
          if(
            this.auditor[0].NATIONAL === 'TRUE' ||
            this.auditor[0].ADMIN === 'TRUE' ||
            this.auditor[0].LOCAL === 'TRUE'
          ){
            this.menuShow = true;
          }
        });
      }
    });
  }

  ngOnInit() {
  }

  getTheAuditor = async (email: string) => await this.dataService.getAuditorByEmail(email).toPromise();

  handleInfoClick = () => {
    // window.location.assign('/auditorinfo');
    // window.location.assign('/info');
    this.route.navigate(['/auditorinfo']);
  };

  handleAddHoursClick = () => {
    // window.location.assign('/add-hours');
    this.route.navigate(['/add-hours']);
  };

  handleReportsClick = () => {
    // window.location.assign('/reports');
    this.route.navigate(['/reports']);
  };

  handleProfileClick =() => {
    // window.location.assign('/profile');
    // this.route.navigateByUrl('/profile');
    this.route.navigate(['/profile'], { queryParams: { upDate: true } });
  };

  handleLoginClick = () => {
    // window.location.assign('/login');
    this.route.navigate(['/login']);
  };

  handleMonthlyClick = () => {
    // window.location.assign('/monthly-stat');
    this.route.navigate(['/monthly-stat']);
  };

  handleOtherReportsClick = () => {
    this.route.navigate(['/other-reports']);
  };

  handleTutorialClick = () => {
    this.route.navigate(['/tutorial']);
  };

}
