/* eslint-disable no-underscore-dangle */
// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import * as moment from 'moment';

// import { equalTo, getDatabase, orderByChild, query, ref, set, get, update, push, child } from 'firebase/database';
// import { UtilityService } from './utility.service';
// import { BehaviorSubject, Observable, pipe } from 'rxjs';
// import { IServices } from './services';
// import { map } from 'rxjs/operators';
// import { ISuccess } from './success';
// import { IAuditors } from './auditor';

// @Injectable({
//   providedIn: 'root'
// })
// export class DataService {
//   private auditorURL = 'https://nationaldianetics.firebaseio.com/theUsers.json';
//   private servicesURL = 'https://nationaldianetics.firebaseio.com/AllServices.json';
//   private successstoriesURL = 'https://nationaldianetics.firebaseio.com/SuccessStoriesData.json';
//   private testServicesURL = 'https://nationaldianetics.firebaseio.com/testdata.json';
//   private tutorialCompletedURL = 'https://nationaldianetics.firebaseio.com/TutorialCompleted.json';

//   private _services = new BehaviorSubject<IServices[]>([]);
//   private _regions = new BehaviorSubject<IServices[]>([]);
//   private _success = new BehaviorSubject<ISuccess[]>([]);

//   private _today = moment();

//   constructor(
//     private http: HttpClient,
//     private utility: UtilityService
//   ) { }

//   // eslint-disable-next-line no-underscore-dangle
//   get services() {  return this._services.asObservable(); }
//   // eslint-disable-next-line no-underscore-dangle
//   get regions() {  return this._regions.asObservable(); }
//   // eslint-disable-next-line no-underscore-dangle
//   get success() {  return this._success.asObservable(); }

//   getAllAuditors = async () => {
//     const allAuditorsResponse = await fetch(this.auditorURL);
//     const userData = await allAuditorsResponse.json();
//     return Object.values(userData);
//   };

//   getAllServices() {
//     return this.http.get(this.servicesURL);
//   }

//   async getAllSuccessStories(): Promise<ISuccess> {
//     const stories = await fetch(this.successstoriesURL);
//     const storiesData = await stories.json();
//     return storiesData;
//   };

//   // getAuditorByEmail(email) {
//   //   return this.http.get(this.auditorURL + '?orderBy="EMAIL"&equalTo="' + email + '"');
//   // }

//   getAuditorByEmail(email: string): Observable<IAuditors> {
//     // Your logic to retrieve the auditor data
//     // When you receive the data, cast it to the Auditor interface
//     return this.http.get<IAuditors>(this.auditorURL + '?orderBy="EMAIL"&equalTo="' + email + '"');
//   }

//   getAuditorByNatID = async (natID: string) => {
//     // return this.http.get(this.auditorURL + '?orderBy="NATID"&equalTo="' + natID + '"');
//     const userResponse = await fetch(this.auditorURL + '?orderBy="NATID"&equalTo="' + natID + '"');
//     const userData = await userResponse.json();
//     // const retunUser = Object.values(userData);
//     return Object.values(userData);
//   };

//   getServicesByNatID(natID: string) { // get services by natid
//     return this.http.get(this.servicesURL + '?orderBy="natid"&equalTo="' + natID + '"');
//   }

//   getAllTestServices() {
//     return this.http.get(this.testServicesURL);
//   }

//   fetchServices() {
//     return this.http
//       .get<{ [key: string]: IServices }>(
//         this.servicesURL
//       )
//       .pipe(
//         map(resData => {
//           const services = [];
//           for (const key in resData) {
//             if (resData.hasOwnProperty(key)) {
//               services.push({ ...resData[key], id: key });
//             }
//           }
//           const tempData = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear());
//           const priorYrData = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear()-1);
//           const priorYrData2 = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear()-2);
//           // eslint-disable-next-line no-underscore-dangle
//           const t = priorYrData.filter(o => moment(o.entryDate).isSameOrBefore(this._today));
//           // console.log('priorYrData', t);
//           return services;
//         }),
//         map(services => {
//           // eslint-disable-next-line no-underscore-dangle
//           this._services.next(services);
//         })
//       );
//   };

//   fetchSuccessStories() {
//     return this.http
//       .get<{ [key: string]: ISuccess }>(
//         this.successstoriesURL
//       )
//       .pipe(
//         map(resData => {
//           const success = [];
//           for (const key in resData) {
//             if (resData.hasOwnProperty(key)) {
//               success.push({ ...resData[key], id: key });
//             }
//           }
//           const tempData = success.filter((item) => new Date(item.loadDate).getFullYear() === new Date().getFullYear());
//           return success;
//         }),
//         map(success => {
//           // eslint-disable-next-line no-underscore-dangle
//           this._success.next(success);
//         })
//       );
//   };

//   addAuditorUpdated = async (updateData: any[]) => {
//     const db = getDatabase();
//     await set(ref(db, 'AuditorUpdate/'), { ...updateData })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   addService = async (service: any[], theID: string) => {
//     const db = getDatabase();
//     await set(ref(db, 'AllServices/' + theID), { ...service })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   addImagePlace = async (imgPlace: any[]) => {
//     const db = getDatabase();
//     await push(ref(db, 'SuccessStoriesData/'), { ...imgPlace })
//     .then(() => this.utility.showAlert('Success Story Upload Has Completed! Thank You!'))
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   // Test Services And Data
//   fetchTestServices() {
//     return this.http
//       .get<{ [key: string]: IServices }>(
//         this.testServicesURL
//       )
//       .pipe(
//         map(resData => {
//           const services = [];
//           for (const key in resData) {
//             if (resData.hasOwnProperty(key)) {
//               services.push({ ...resData[key], id: key });
//             }
//           }
//           const tempData = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear());
//           return tempData;
//         }),
//         map(services => {
//           // eslint-disable-next-line no-underscore-dangle
//           this._services.next(services);
//         })
//       );
//   };

//   addTestService = async (service: any[], theID: string) => {
//     const db = getDatabase();
//     await set(ref(db, 'testdata/' + theID), { ...service })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   addTestImagePlace = async (imgPlace: any[]) => {
//     const db = getDatabase();
//     await push(ref(db, '4testdata/'), { ...imgPlace })
//     .then(() => this.utility.showAlert('Success Story Upload Has Completed! Thank You!'))
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   addTestAuditor = async (auditor: IAuditors[], theID: string) => {
//     const db = getDatabase();
//     await set(ref(db, 'testusers/' + theID), { ...auditor })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   addAuditor = async (auditor: IAuditors[], theID: string) => {
//     console.log('AddAuditor: ', auditor);
//     const db = getDatabase();
//     await set(ref(db, 'theUsers/' + theID), { ...auditor })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   updateTestAuditor = async (auditor: IAuditors[], theID: string) => {
//     const db = getDatabase();
//     await update(ref(db, 'testusers/' + theID), { ...auditor })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   updateAuditor = async (auditor: IAuditors[], theID: string) => {
//     const db = getDatabase();
//     await update(ref(db, 'theUsers/' + theID), { ...auditor })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   setLoginDate = async (auditor: IAuditors[]) => {
//     const db = getDatabase();
//     await push(ref(db, 'auditlogin/'), { ...auditor })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   setTutorialDate = async (tutorialDone) => {
//     const db = getDatabase();
//     await push(ref(db, 'TutorialCompleted/'), { ...tutorialDone })
//     .then(() => true)
//     .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
//   };

//   getTutorialByNatID = async (natID: string) => {
//     const userResponse = await fetch(this.tutorialCompletedURL + '?orderBy="natid"&equalTo="' + natID + '"');
//     const userData = await userResponse.json();
//     return Object.values(userData);
//   };

// }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IServices } from './services';
import { UtilityService } from './utility.service';
import { ISuccess } from './success';
import { IAuditors } from './auditor';
import { getDatabase, ref, set, push, update } from 'firebase/database';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private auditorURL = 'https://nationaldianetics.firebaseio.com/theUsers.json';
  private servicesURL = 'https://nationaldianetics.firebaseio.com/AllServices.json';
  private successstoriesURL = 'https://nationaldianetics.firebaseio.com/SuccessStoriesData.json';
  private testServicesURL = 'https://nationaldianetics.firebaseio.com/testdata.json';
  private tutorialCompletedURL = 'https://nationaldianetics.firebaseio.com/TutorialCompleted.json';

  private _services = new BehaviorSubject<IServices[]>([]);
  private _regions = new BehaviorSubject<IServices[]>([]);
  private _success = new BehaviorSubject<ISuccess[]>([]);

  private _today = moment();

  constructor(
    private http: HttpClient,
    private utility: UtilityService
  ) { }

  get services(): Observable<IServices[]> {
    return this._services.asObservable();
  }

  get regions(): Observable<IServices[]> {
    return this._regions.asObservable();
  }

  get success(): Observable<ISuccess[]> {
    return this._success.asObservable();
  }

  getAllAuditors = async () => {
    const allAuditorsResponse = await fetch(this.auditorURL);
    const userData = await allAuditorsResponse.json();
    return Object.values(userData);
  };

  getAllServices() {
    return this.http.get(this.servicesURL);
  }

  async getAllSuccessStories(): Promise<ISuccess> {
    const stories = await fetch(this.successstoriesURL);
    const storiesData = await stories.json();
    return storiesData;
  };

  getAuditorByEmail(email: string): Observable<IAuditors> {
    // Your logic to retrieve the auditor data
    // When you receive the data, cast it to the Auditor interface
    return this.http.get<IAuditors>(this.auditorURL + '?orderBy="EMAIL"&equalTo="' + email + '"');
  }

  // getAuditorByNatID = async (natID: string) => {
  //   const userResponse = await fetch(this.auditorURL + '?orderBy="NATID"&equalTo="' + natID + '"');
  //   const userData = await userResponse.json();
  //   return Object.values(userData);
  // };

  async getAuditorByNatID(natID: string): Promise<IAuditors> {
    const auditor = await fetch(this.auditorURL + '?orderBy="NATID"&equalTo="' + natID + '"');
    const auditorData = await auditor.json();
    return auditorData;
  };

  getServicesByNatID(natID: string) { // get services by natid
    return this.http.get(this.servicesURL + '?orderBy="natid"&equalTo="' + natID + '"');
  }

  getAllTestServices() {
    return this.http.get(this.testServicesURL);
  }

  fetchServices() {
    return this.http
      .get<{ [key: string]: IServices }>(
        this.servicesURL
      )
      .pipe(
        map(resData => {
          const services = [];
          for (const key in resData) {
            if (resData.hasOwnProperty(key)) {
              services.push({ ...resData[key], id: key });
            }
          }
          const tempData = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear());
          const priorYrData = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear() - 1);
          const priorYrData2 = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear() - 2);
          // eslint-disable-next-line no-underscore-dangle
          const t = priorYrData.filter(o => moment(o.entryDate).isSameOrBefore(this._today));
          // console.log('priorYrData', t);
          return services;
        }),
        map(services => {
          // eslint-disable-next-line no-underscore-dangle
          this._services.next(services);
        })
      );
  };

  fetchSuccessStories() {
    return this.http
      .get<{ [key: string]: ISuccess }>(
        this.successstoriesURL
      )
      .pipe(
        map(resData => {
          const success = [];
          for (const key in resData) {
            if (resData.hasOwnProperty(key)) {
              success.push({ ...resData[key], id: key });
            }
          }
          const tempData = success.filter((item) => new Date(item.loadDate).getFullYear() === new Date().getFullYear());
          return success;
        }),
        map(success => {
          // eslint-disable-next-line no-underscore-dangle
          this._success.next(success);
        })
      );
  };

  addAuditorUpdated = async (updateData: any[]) => {
    const db = getDatabase();
    await set(ref(db, 'AuditorUpdate/'), { ...updateData })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  addService = async (service: any[], theID: string) => {
    const db = getDatabase();
    await set(ref(db, 'AllServices/' + theID), { ...service })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  addImagePlace = async (imgPlace: any[]) => {
    const db = getDatabase();
    await push(ref(db, 'SuccessStoriesData/'), { ...imgPlace })
      .then(() => this.utility.showAlert('Success Story Upload Has Completed! Thank You!'))
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  // Test Services And Data
  fetchTestServices() {
    return this.http
      .get<{ [key: string]: IServices }>(
        this.testServicesURL
      )
      .pipe(
        map(resData => {
          const services = [];
          for (const key in resData) {
            if (resData.hasOwnProperty(key)) {
              services.push({ ...resData[key], id: key });
            }
          }
          const tempData = services.filter((item) => new Date(item.entryDate).getFullYear() === new Date().getFullYear());
          return tempData;
        }),
        map(services => {
          // eslint-disable-next-line no-underscore-dangle
          this._services.next(services);
        })
      );
  };

  addTestService = async (service: any[], theID: string) => {
    const db = getDatabase();
    await set(ref(db, 'testdata/' + theID), { ...service })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  addTestImagePlace = async (imgPlace: any[]) => {
    const db = getDatabase();
    await push(ref(db, '4testdata/'), { ...imgPlace })
      .then(() => this.utility.showAlert('Success Story Upload Has Completed! Thank You!'))
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  addTestAuditor = async (auditor: IAuditors[], theID: string) => {
    const db = getDatabase();
    await set(ref(db, 'testusers/' + theID), { ...auditor })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  addAuditor = async (auditor: IAuditors[], theID: string) => {
    console.log('AddAuditor: ', auditor);
    const db = getDatabase();
    await set(ref(db, 'theUsers/' + theID), { ...auditor })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  updateTestAuditor = async (auditor: IAuditors[], theID: string) => {
    const db = getDatabase();
    await update(ref(db, 'testusers/' + theID), { ...auditor })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  updateAuditor = async (auditor: IAuditors[], theID: string) => {
    const db = getDatabase();
    await update(ref(db, 'theUsers/' + theID), { ...auditor })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  setLoginDate = async (auditor: IAuditors[]) => {
    const db = getDatabase();
    await push(ref(db, 'auditlogin/'), { ...auditor })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  setTutorialDate = async (tutorialDone) => {
    const db = getDatabase();
    await push(ref(db, 'TutorialCompleted/'), { ...tutorialDone })
      .then(() => true)
      .catch((error) => this.utility.showAlert(error + '  PLEASE CONTACT TECH SUPPORT WITH THIS ERROR AT techsupport@noiauditing'));
  };

  getTutorialByNatID = async (natID: string) => {
    const userResponse = await fetch(this.tutorialCompletedURL + '?orderBy="natid"&equalTo="' + natID + '"');
    const userData = await userResponse.json();
    return Object.values(userData);
  };

}
