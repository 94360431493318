/* eslint-disable @typescript-eslint/member-ordering */
// import { Injectable, NgZone } from '@angular/core';
// // import * as auth from 'firebase/auth';
// import { Router } from '@angular/router';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
// import { UtilityService } from './utility.service';
// import { AngularFireDatabase } from '@angular/fire/compat/database';

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthenticationService {
//   userData: any;
//   token;

//   constructor(
//     public afStore: AngularFirestore,
//     public afDB: AngularFireDatabase,
//     public ngFireAuth: AngularFireAuth,
//     public router: Router,
//     public ngZone: NgZone,
//     private utility: UtilityService
//   ) {
//     this.ngFireAuth.authState.subscribe((user) => {
//       if (user) {
//         this.userData = user;
//         localStorage.setItem('user', JSON.stringify(this.userData));
//       } else {
//         localStorage.setItem('user', null);
//         localStorage.setItem('auditor', null);
//         localStorage.setItem('DelHRS', null);
//         localStorage.setItem('RecHRS', null);
//         localStorage.setItem('MonTOTHRS', null);
//       }
//     });
//   }
//   // Login in with email/password
//   async signIn(email, password) {
//     return await this.ngFireAuth.signInWithEmailAndPassword(email, password);
//   }
//   // Register user with email/password
//   async registerUser(email, password) {
//     return await this.ngFireAuth.createUserWithEmailAndPassword(email, password);
//   }
//   // Email verification when new user register
//   async sendVerificationMail() {
//     return await this.ngFireAuth.currentUser.then((user) => user.sendEmailVerification().then(() => {
//       window.location.assign('/verify-email');
//     }));
//   }
//   // Recover password
//   async passwordRecover(passwordResetEmail): Promise<void> {
//     return await this.ngFireAuth
//       .sendPasswordResetEmail(passwordResetEmail)
//       .then(() => {
//         this.utility.showAlert(
//           'Password reset password email has been sent, please check your email inbox and your SPAM folder.'
//         );
//       })
//       .catch((error) => {
//         if(error.code === 'auth/user-not-found'){
//           this.utility.showAlert('This email was not found. Please check and try again.');
//         } else {
//           this.utility.showAlert(error.message);
//         }
//       });
//   }
//   // Returns true when user is looged in
//   isLoggedIn(): boolean {
//     const user = JSON.parse(localStorage.getItem('user'));
//     return user !== null; // && user.emailVerified !== false ? true : false;
//   }
//   // Returns true when user's email is verified
//   isEmailVerified(): boolean {
//     const user = JSON.parse(localStorage.getItem('user'));
//     return user.emailVerified !== false ? true : false;
//   }
//   // Sign in with Gmail
//   // async googleAuth() {
//   //   return await this.authLogin(new auth.GoogleAuthProvider());
//   // }
//   // Auth providers
//   async authLogin(provider) {
//     return await this.ngFireAuth
//       .signInWithPopup(provider)
//       .then((result) => {
//         this.ngZone.run(() => {
//           this.router.navigate(['dashboard']);
//         });
//         this.setUserData(result.user);
//       })
//       .catch((error) => {
//         this.utility.showAlert(error);
//       });
//   }
//   // Store user in localStorage
//   async setUserData(user) {
//     const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
//       `users/${user.uid}`
//     );
//     const userData = {
//       uid: user.uid,
//       email: user.email,
//       displayName: user.displayName,
//       photoURL: user.photoURL,
//       emailVerified: user.emailVerified,
//     };
//     return await userRef.set(userData, {
//       merge: true,
//     });
//   }
//   // Sign-out
//   async signOut() {
//     return await this.ngFireAuth.signOut().then(() => {
//       localStorage.removeItem('currentMonthServices');
//       localStorage.removeItem('currentYearServices');
//       localStorage.removeItem('auditorMonthHours');
//       localStorage.removeItem('CurrentMonth');
//       localStorage.removeItem('DelHRS');
//       localStorage.removeItem('RecHRS');
//       localStorage.removeItem('MonTOTHRS');
//       localStorage.removeItem('tt');
//       localStorage.removeItem('auditorsuccess');
//       localStorage.removeItem('tableData');
//       this.router.navigate(['/login']);
//     });
//   }
// }


import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userData: any;

  constructor(
    private afStore: AngularFirestore,
    private ngFireAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
    private utility: UtilityService
  ) {
    this.ngFireAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        this.clearLocalStorage();
      }
    });
  }

  private clearLocalStorage(): void {
    const keysToRemove = [
      'user', 'auditor', 'DelHRS', 'RecHRS', 'MonTOTHRS',
      'currentMonthServices', 'currentYearServices', 'auditorMonthHours',
      'CurrentMonth', 'tt', 'auditorsuccess', 'tableData'
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
  }

  async signIn(email: string, password: string) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  async registerUser(email: string, password: string) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  async sendVerificationMail(): Promise<void> {
    const user = await this.ngFireAuth.currentUser;
    if (user) {
      await user.sendEmailVerification();
      window.location.assign('/verify-email');
    }
  }

  async passwordRecover(passwordResetEmail: string): Promise<void> {
    try {
      await this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail);
      this.utility.showAlert('Password reset email has been sent. Please check your inbox and SPAM folder.');
    } catch (error) {
      this.handlePasswordRecoverError(error);
    }
  }

  private handlePasswordRecoverError(error: any): void {
    if (error.code === 'auth/user-not-found') {
      this.utility.showAlert('This email was not found. Please check and try again.');
    } else {
      this.utility.showAlert(error.message);
    }
  }

  isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem('user')) !== null;
  }

  isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.emailVerified === true;
  }

  async authLogin(provider: any): Promise<void> {
    try {
      const result = await this.ngFireAuth.signInWithPopup(provider);
      this.ngZone.run(() => this.router.navigate(['dashboard']));
      this.setUserData(result.user);
    } catch (error) {
      this.utility.showAlert(error);
    }
  }

  async setUserData(user: any): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    await userRef.set(userData, { merge: true });
  }

  async signOut(): Promise<void> {
    await this.ngFireAuth.signOut();
    this.clearLocalStorage();
    this.router.navigate(['/login']);
  }
}
